import {Box, Divider, Tooltip, Button} from "@material-ui/core";
import {CheckBox, CheckCircle, Close, Image} from "@material-ui/icons";
import {COLOR} from "../../theme";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {useHistory} from "react-router-dom";


const TaskTile = ({ task, projectId }) => {

  const { isPm } = useSelector(userSelector)
  const history = useHistory()

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <h3>{task.name}</h3>
          <p>{task.location.city}, ul. {task.location.street} {task.location.buildingNumber}</p>
        </Box>
        <Box display='flex'>
          <Box m={2}>
            <Tooltip title={task.documents.length === 0 ? 'Brak dodanych dokumentów' : `Liczba dokumentów: ${task.documents.length}`} placement='top'>
              <div style={{position: 'relative'}}>
                <Image style={{fontSize: 60, color: 'rgb(160, 208, 236)'}} />
                <div style={{position: 'absolute', bottom: 0, right: 0}}>
                  {
                    task.documents.length === 0
                      ? <div
                        style={{
                          height: '26px',
                          width: '26px',
                          background: 'red',
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <Close />
                      </div>
                      : <div
                        style={{
                          height: '26px',
                          width: '26px',
                          background: COLOR.primary,
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        {task.documents.length}
                      </div>
                  }
                </div>
              </div>
            </Tooltip>
          </Box>
          {isPm ? <Box m={2} display='flex' alignItems='center'>
              <Button fullWidth variant='contained' color='primary' onClick={() => history.push(`/tasks/${projectId}/${task.id}`)}>
                Szczegóły
              </Button>
            </Box>
            : <Box m={2}>
              <Tooltip title={task.status === 'Zrealizowane' ? 'Zadanie zrealizowane' : 'Zadanie do realizacji'}
                       placement='top'>
                <CheckBox
                  style={{fontSize: 60, color: task.status === 'Zrealizowane' ? COLOR.success : COLOR.lightGrey}}/>
              </Tooltip>
            </Box>
          }
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}

export default TaskTile
