import {useHistory, useParams} from "react-router-dom";
import {Box, Button, CircularProgress, Divider, Grid, LinearProgress} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getProject, getTasks} from "./ProjectsSlice";
import TaskTile from "../../common/Components/TaskTile";
import {api} from "../../api";
import {toast} from "react-toastify";

const ClientProjectDetails = () => {

  const [projectForClient, setProjectForClient] = useState(null)
  const [generatingReport, setGeneratingReport] = useState(false)
  const [tasks, setTasks] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const { projectId } = useParams()


  useEffect(() => {
    dispatch(getProject(projectId)).then(res => setProjectForClient(res.payload))
    dispatch(getTasks({projectId})).then(res => setTasks(res.payload.tasks))
  }, [])

  const getReport = () => {
    setGeneratingReport(true)
    return api.get(
      `/SSingleOrders/Pdf/${projectId}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token'),

        },
        params: {
          templates: [37]
        }
      }
    ).then(res => {

      const pdf = res.data.result.data
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = res.data.result.name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setGeneratingReport(false)


    }, error => {
      toast.error('Nie udało się wygenerować raportu.')
      setGeneratingReport(false)
    })
  }

  if (!projectForClient) {
    return <Box m={10}>
      <CircularProgress />
    </Box>
  }

  return (
    <Box textAlign='left' p={2}>
      <Box mb={2}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => history.push('/')}
          color='secondary'
        >
          Wróc do listy projektów
        </Button>
      </Box>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={3} xl={2}>
                    <h3 style={{textAlign: 'right'}}>Numer projektu:</h3>
                  </Grid>
                  <Grid item xs={9} xl={10}>
                    <h3 style={{marginLeft: '16px'}}>{projectForClient.number}</h3>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Projekt:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForClient.name}</p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>PO:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForClient.clientProjectNumber}</p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin umowy:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForClient.deadline}</p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin wykonania:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForClient.implementationDate}</p>
              </Grid>

            </Grid>
          </Grid>


          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontrahent:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForClient.contractor}</p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontakt:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {
                    projectForClient.contact ? <span>
                        {projectForClient.contact.name},&nbsp;
                        <a href={`mailto: ${projectForClient.contact.email}`}>{projectForClient.contact.email}</a>&nbsp;
                        <a href={`tel: ${projectForClient.contact.phone}`}>{projectForClient.contact.phone}</a>
                      </span>
                      : '-'
                  }
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Project Manager:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {
                    projectForClient.projectManager ? <span>
                        {projectForClient.projectManager.name},&nbsp;
                        <a href={`mailto: ${projectForClient.projectManager.email}`}>{projectForClient.projectManager.email}</a>&nbsp;
                        <a href={`tel: ${projectForClient.projectManager.phone}`}>{projectForClient.projectManager.phone}</a>
                      </span>
                      : '-'
                  }
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Account Manager:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {
                    projectForClient.accountManager ? <span>
                        {projectForClient.accountManager.name},&nbsp;
                        <a href={`mailto: ${projectForClient.accountManager.email}`}>{projectForClient.accountManager.email}</a>&nbsp;
                        <a href={`tel: ${projectForClient.accountManager.phone}`}>{projectForClient.accountManager.phone}</a>
                      </span>
                      : '-'
                  }
                </p>
              </Grid>

            </Grid>
          </Grid>


        </Grid>
      </Box>
      <Divider />
      <h3>Postęp projektu:</h3>
      <Box height='100%' display='flex' alignItems='center'>
        <div style={{height: '40px', width: '100%', position: 'relative'}}>
          <p
            style={{margin: 0, position: 'absolute', color: '#fff', fontWeight: 900, zIndex: 1, height: '40px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          >
            {projectForClient.ready}/{projectForClient.total} {projectForClient.total === 0 ? 0 : (projectForClient.ready / projectForClient.total * 100).toFixed(0)}%
          </p>
          <LinearProgress style={{height: '40px', width: '100%', borderRadius: '8px'}} variant='determinate' value={projectForClient.total === 0 ? 0 : (projectForClient.ready / projectForClient.total * 100).toFixed(0)}/>
        </div>
        <Box minWidth='250px' ml={2}>
          <Button disabled={generatingReport} startIcon={generatingReport && <CircularProgress style={{height: '20px', width: '20px'}} />} onClick={getReport} fullWidth color='primary' variant='contained'>
            Generuj raport pdf
          </Button>
        </Box>
      </Box>
      <Box mt={5}>
        <Grid container>
          {tasks.map(task => <Grid item xs={12}><TaskTile task={task} /></Grid>)}
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientProjectDetails
