import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {Box, Button, CircularProgress, Divider, Grid, LinearProgress} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getProject, getTasks, setStartedPage} from "./ProjectsSlice";
import TaskTile from "../../common/Components/TaskTile";
import TaskDetails from "../../common/Components/TaskDetails";
import Pagination from "@material-ui/lab/Pagination";

// const projectForPm = {
//   number: '236-07-20-AGAJ-AESQ', //Numer projektu
//   implementationDate: '2021-05-20', //Termin wykonania
//   contractor: 'Netinteractive Spółka Z Ograniczoną Odpowiedzialnością', //Kontrahent
//   suppliers: ['Dostawca1 sp. z o. o.', 'Dostawca2  sp. z o. o.'] //Wszyscy dostawcy (nazwy) powiazani z projektem
// }

const PmTasks = () => {

  const [projectForPm, setProjectForPm] = useState(null)
  const [doneTasks, setDoneTasks] = useState([])
  const [toDoTasks, setToDoTasks] = useState([])
  const [doneTasksPage, setDoneTasksPage] = useState(1)
  const [toDoTasksPage, setToDoTasksPage] = useState(1)
  const [doneTasksLastPage, setDoneTasksLastPage] = useState(1)
  const [toDoTasksLastPage, setToDoTasksLastPage] = useState(1)
  const dispatch = useDispatch()
  const history = useHistory()
  const { projectId } = useParams()

  const [toDoTasksFetched, setToDoTasksFetched] = useState(false)
  const [doneTasksFetched, setDoneTasksFetched] = useState(false)



  useEffect(() => {
    dispatch(getProject(projectId)).then(res => setProjectForPm(res.payload))
  }, [])

  useEffect(() => {
    getDoneTasks()
  }, [toDoTasksPage, history.location])

  useEffect(() => {
    getToDoTasks()
  }, [doneTasksPage, history.location])

  const getDoneTasks = () => {
    dispatch(getTasks({projectId, status: 'Do realizacji', page: toDoTasksPage})).then(res => {
      setToDoTasks(res.payload.tasks)
      setToDoTasksLastPage(res.payload.lastPage)
      setToDoTasksFetched(true)
    })
  }

  const getToDoTasks = () => {
    dispatch(getTasks({projectId, status: 'Zrealizowane', page: doneTasksPage})).then(res => {
      setDoneTasks(res.payload.tasks)
      setDoneTasksLastPage(res.payload.lastPage)
      setDoneTasksFetched(true)
    })
  }

  if (!projectForPm) {
    return <Box m={10}>
      <CircularProgress />
    </Box>
  }

  return (
    <Switch>
      <Route exact path={`/tasks/${projectId}`} render={() => {
          return <Box textAlign='left' p={2}>
          <Box mb={2}>
            <Button
              startIcon={<ArrowBack />}
              onClick={() => history.push('/')}
              color='secondary'
            >
              Wróc do listy projektów
            </Button>
          </Box>
          <Box mb={2}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={3} xl={2}>
                    <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontrahent:</b></p>
                  </Grid>
                  <Grid item xs={9} xl={10}>
                    <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForPm.contractor || '-'}</p>
                  </Grid>

                  <Grid item xs={3} xl={2}>
                    <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin realizacji</b></p>
                  </Grid>
                  <Grid item xs={9} xl={10}>
                    <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForPm.implementationDate || '-'}</p>
                  </Grid>

                </Grid>
              </Grid>


              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={3} xl={2}>
                    <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Numer projektu:</b></p>
                  </Grid>
                  <Grid item xs={9} xl={10}>
                    <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForPm.number || '-'}</p>
                  </Grid>

                  <Grid item xs={3} xl={2}>
                    <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Dostawcy:</b></p>
                  </Grid>
                  <Grid item xs={9} xl={10}>
                    <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                      {projectForPm.suppliers.length === 0 ? '-' : projectForPm.suppliers.join(', ')}
                    </p>
                  </Grid>

                </Grid>
              </Grid>


            </Grid>
          </Box>
          <Divider />
          <Box mt={5}>
            <h3>Zadania niezrealizowane:</h3>
            <Divider />
            {toDoTasks.length === 0 ? <p>Brak zadań niezrealizowanych</p> : <Box>
                <Grid container>
                  {toDoTasks.map(task => <Grid item xs={12}><TaskTile projectId={projectId} task={task}/></Grid>)}
                </Grid>
                <Box mt={2} display='flex' justifyContent='flex-end'>
                  <Pagination shape='rounded' page={toDoTasksPage} onChange={(event, page) => setToDoTasksPage(page)}
                              count={toDoTasksLastPage}/>
                </Box>
              </Box>
            }
          </Box>
            <Box mt={5}>
              <h3>Zadania zrealizowane:</h3>
              <Divider />
              {doneTasks.length === 0 ? <p>Brak zadań zrealizowanych</p> : <Box>
                  <Grid container>
                    {doneTasks.map(task => <Grid item xs={12}><TaskTile projectId={projectId} task={task} /></Grid>)}
                  </Grid>
                  <Box mt={2} display='flex' justifyContent='flex-end'>
                    <Pagination shape='rounded' page={doneTasksPage} onChange={(event, page) => setDoneTasksPage(page)} count={doneTasksLastPage} />
                  </Box>
                </Box>
              }
            </Box>
        </Box>
        }}
      />
      <Route exact path={`/tasks/${projectId}/:taskId`} render={() => <TaskDetails tasks={[...doneTasks, ...toDoTasks]} doneTasksFetched={doneTasksFetched} toDoTasksFetched={toDoTasksFetched} projectId={projectId} />} />
    </Switch>
  )
}

export default PmTasks
