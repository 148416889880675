import React, {useEffect, useState} from 'react'
import {Box, Button, Dialog, DialogContent, Divider, Grid, LinearProgress, CircularProgress} from "@material-ui/core";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {api} from "../../api";
import IconButton from "@material-ui/core/IconButton";
import {Close, InsertDriveFile, PictureAsPdf} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {DONE_STATUS, INVOICED_STATUS} from "../../features/Projects/ProjectsSlice";

const ProjectByLocationTile = ({project}) => {

  const history = useHistory()
  const { isPm } = useSelector(userSelector)
  const [ imagePreview, setImagePreview ] = useState(false)
  const [ showImagePreview, setShowImagePreview ] = useState(false)
  const [ preAssemblyDocs, setPreAssemblyDocs ] = useState([])
  const [ postAssemblyDocs, setPostAssemblyDocs ] = useState([])
  const [ documentsLoaded, setDocumentsLoaded ] = useState(true)

  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  useEffect(() => {
    if (project.documents.length > 0) {
      setDocumentsLoaded(false)
      const preAssembly = project.documents.filter(doc => doc.type === 'Dokumentacja przedmontażowa')
      const postAssembly = project.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')

      Promise.all(preAssembly.map(preAssemblyDocument => {
        return getFile(preAssemblyDocument).then(res => ({...preAssemblyDocument, url: res?.data?.result.content, type: res?.data?.result.type}))
      })).then(res => {
        setPreAssemblyDocs(res)
      })

      Promise.all(postAssembly.map(preAssemblyDocument => {
        return getFile(preAssemblyDocument).then(res => ({...preAssemblyDocument, url: res?.data?.result.content, type: res?.data?.result.type}))
      })).then(res => {
        setPostAssemblyDocs(res)
        setDocumentsLoaded(true)
      })
    }
  }, [])


  return (
    <Box>
      <Divider />
      <Grid container>
        <Grid item xs={isPm ? 7 : 8} lg={isPm ? 9 : 10}>
          <Box pr={2}>
            <h3>{project.number}</h3>
            <p>
              {`Termin umowy: ${project.contractTerm || 'brak'} || ${project.description}`}
            </p>
            <p>Status: <b>{project.status}</b></p>
          </Box>
        </Grid>
        {isPm && <Grid item xs={2}>
          <Box pr={2} height='100%' display='flex' alignItems='center'>
            <div style={{height: '40px', width: '100%', position: 'relative'}}>
              <p
                style={{margin: 0, position: 'absolute', color: '#fff', fontWeight: 900, zIndex: 1, height: '40px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              >
                {project.ready}/{project.total} {project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}%
              </p>
              <LinearProgress style={{height: '40px', width: '100%', borderRadius: '8px'}} variant='determinate' value={project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}/>
            </div>
          </Box>
        </Grid>}
        <Grid item xs={3} lg={isPm ? 1 : 2}>
          <Box height='100%' display='flex' alignItems='center'>
            <Button
              fullWidth
              color={project.status === INVOICED_STATUS || project.status === DONE_STATUS ? 'default' : 'primary'}
              variant='contained'
              onClick={() => isPm ? history.push(`/tasks/${project.id}`) : history.push(`/project/${project.id}`)}
            >
              {isPm ? 'Zadania' : 'Szczegóły'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} mb={5}>
          <h3>Dokumentacja przedmontażowa:</h3>
          {!documentsLoaded
            ? <CircularProgress />
            : preAssemblyDocs.length === 0
            ? <p>Brak dokumentów</p>
            : <Grid container alignItems='center'>
            {preAssemblyDocs.map(doc => {
              if (doc.type === 'image/png' || doc.type === 'image/jpg' || doc.type === 'image/jpeg') {
                return (
                  <Grid item xs={2}>
                    <Box mr={2}>
                      <img
                        onClick={() => {
                          setShowImagePreview(true)
                          setImagePreview(`${doc.url}`)
                        }}
                        style={{maxWidth: '100%', borderRadius: '8px', cursor: 'pointer'}}
                        alt={doc.title}
                        src={`${doc.url}`}
                      />
                      <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all'}}>
                        {doc.fileName}
                      </p>
                    </Box>
                  </Grid>
                )
              } else if (doc.type === 'application/pdf') {
                return (
                  <Grid item xs={2}>
                    <Box mr={2}>
                      {/*<img*/}
                      {/*  onClick={() => {*/}
                      {/*    setShowImagePreview(true)*/}
                      {/*    setImagePreview(`${doc.url}`)*/}
                      {/*  }}*/}
                      {/*  style={{maxWidth: '100%', borderRadius: '8px', cursor: 'pointer'}}*/}
                      {/*  alt={doc.title}*/}
                      {/*  src={`${doc.url}`}*/}
                      {/*/>*/}
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = doc.url; //Image Base64 Goes here
                          a.download = doc.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          <PictureAsPdf style={{fontSize: 80}} />
                        </IconButton>
                      </Box>
                      <p
                        onClick={() => {
                          var a = document.createElement("a");
                          a.href = doc.url;
                          a.download = doc.fileName;
                          a.click();
                        }}
                        style={{textAlign: 'center', cursor: 'pointer', marginTop: '5px', wordBreak: 'break-all'}}>
                        {doc.fileName}
                      </p>
                    </Box>
                  </Grid>
                )
              } else {
                return (
                  <Grid item xs={2}>
                    <Box mr={2}>
                      {/*<img*/}
                      {/*  onClick={() => {*/}
                      {/*    setShowImagePreview(true)*/}
                      {/*    setImagePreview(`${doc.url}`)*/}
                      {/*  }}*/}
                      {/*  style={{maxWidth: '100%', borderRadius: '8px', cursor: 'pointer'}}*/}
                      {/*  alt={doc.title}*/}
                      {/*  src={`${doc.url}`}*/}
                      {/*/>*/}
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = doc.url; //Image Base64 Goes here
                          a.download = doc.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          <InsertDriveFile style={{fontSize: 80}} />
                        </IconButton>
                      </Box>
                      <p
                        onClick={() => {
                          var a = document.createElement("a");
                          a.href = doc.url;
                          a.download = doc.fileName;
                          a.click();
                        }}
                        style={{textAlign: 'center', cursor: 'pointer', marginTop: '5px', wordBreak: 'break-all'}}>
                        {doc.fileName}
                      </p>
                    </Box>
                  </Grid>
                )
              }
            })}
          </Grid>}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} mb={5}>
          <h3>Dokumentacja pomontażowa:</h3>
          {!documentsLoaded
            ? <CircularProgress />
            : postAssemblyDocs.length === 0
              ? <p>Brak dokumentów</p>
              : <Grid container alignItems='center'>
                {postAssemblyDocs.map(doc => {
                  return (
                    <Grid item xs={2}>
                      <Box mr={2}>
                        <img
                          onClick={() => {
                            setShowImagePreview(true)
                            setImagePreview(`${doc.url}`)
                          }}
                          style={{maxWidth: '100%', borderRadius: '8px', cursor: 'pointer'}}
                          alt={doc.title}
                          src={`${doc.url}`}
                        />
                        <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all'}}>
                          {doc.fileName}
                        </p>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            }
        </Box>
      </Grid>
      <Dialog maxWidth='lg' open={showImagePreview} onClose={() => {
        setShowImagePreview(false)
      }}>
        <Box display='flex' justifyContent='flex-end'>
          <IconButton size='large' onClick={() => {
            setShowImagePreview(false)
          }}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          {imagePreview && <img style={{height: 'auto', width: '100%'}} alt='preview' src={imagePreview}/>}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ProjectByLocationTile
