import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
  getDoneOrInvoicedProjects,
  getProjects,
  getStartedProjects,
  setStartedPage,
  setDoneOrInvoicedPage,
  projectsSelector,
  getProjectsFilteredByLocations
} from "./ProjectsSlice";
import {Box, CircularProgress, Divider, Grid, IconButton, MenuItem, Button, Select, Fade, TextField} from '@material-ui/core'
import {Autocomplete} from "@material-ui/lab";
import ProjectTile from "../../common/Components/ProjectTile";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {ArrowBack, ArrowForward, ArrowRight, Search} from "@material-ui/icons";
import {userSelector} from "../User/UserSlice";
import Pagination from '@material-ui/lab/Pagination';
import {useHistory} from "react-router-dom";
import ProjectByLocationTile from "../../common/Components/ProjectByLocationTile";

const Locations = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {started, doneOrInvoiced, filteredByLocations, contractors, isFetching} = useSelector(projectsSelector)
  const {isPm, fetchedUser, locations} = useSelector(userSelector)

  const [locationsSearchValue, setLocationsSearchValue] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])

  useEffect(() => {
    dispatch(getProjectsFilteredByLocations({ locations: selectedLocations, page: filteredByLocations.page }))
  }, [filteredByLocations.page])

  const locationsOptions = locations.map(location => ({
    label: `${location.name} (${location.street} ${location.buildingNumber}${location.flatNumber ? `/${location.flatNumber}` : ''}, ${location.postalCode}, ${location.city})`,
    value: location.id
  }))

  return (
    <Fade in={fetchedUser}>
      <Box textAlign='left' p={2}>
        <Box mb={2}>
          <Button
            startIcon={<ArrowBack />}
            onClick={() => history.push('/')}
            color='secondary'
          >
            Wróc do listy projektów
          </Button>
        </Box>
        <Box mb={5}>
          <Grid container alignItems='center'>
            <Grid item xs={6} lg={4}>
              <Box display='flex' alignItems='center' mb={2}>
                <Autocomplete
                  fullWidth
                  multiple
                  filterSelectedOptions
                  id="tags-outlined"
                  noOptionsText='Brak wyników'
                  options={locationsOptions.filter(option => !selectedLocations.includes(option.value))}
                  getOptionLabel={(option) => option.label}
                  onChange={(ev, values) => {
                    setSelectedLocations(values.map(({value}) => value))
                    return values
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Wybierz lokalizacje"
                    />
                  )}
                />
                <IconButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch(getProjectsFilteredByLocations({ locations: selectedLocations, page: filteredByLocations.page })).then(() =>
                      setLocationsSearchValue(selectedLocations)
                    )
                  }}
                  style={{marginLeft: '10px'}}
                >
                  <ArrowForward/>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Divider />
        </Box>
        <Box>
          <h2>
            Projekty według lokalizacji:
          </h2>
          {
            locationsSearchValue.length === 0 ? <p style={{margin: '20px 0'}}>Wybierz jedną lub więcej lokalizacji, aby wyświetlić powiązane z nimi projekty.</p>
              : filteredByLocations.recordsList.length === 0
                ? <p style={{margin: '20px 0'}}>Brak wyników</p>
                : filteredByLocations.recordsList.map(
                  item => {
                    return <ProjectByLocationTile key={item.id} project={item} />
                  }
                )
          }
          <Divider />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Pagination shape='rounded' page={started.page} onChange={(event, page) => dispatch(setStartedPage(page))} count={started.lastPage} />
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

export default Locations
