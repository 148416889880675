import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Login from './features/User/Login';
import Dashboard from './features/User/Dashboard';
import {PrivateRoute} from './helpers/PrivateRoute';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path='/login' component={Login} />
          <PrivateRoute component={Dashboard} path={`/`} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
