import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {api} from "../../api";
import {userSlice} from "../User/UserSlice";

export const INVOICED_STATUS = '10.Projekt zafakturowany'
export const DONE_STATUS = '09.Projekt zrealizowany'
export const STARTED_STATUS = '08.Projekt uruchomiony'

export const getProjectsFilteredByLocations = createAsyncThunk(
  'projects/filteredByLocations/recordsList',
  async ({number, contractor, statuses, locations, page = 1}, thunkAPI) => {
    if (locations.length === 0) {
      return {
        orders: [],
        lastPage: 0
      }
    }
    const headers = {}

    headers.statuses = JSON.stringify([DONE_STATUS, INVOICED_STATUS, STARTED_STATUS])

    headers['X-TOKEN'] = localStorage.getItem('token')
    if (number) {
      headers.number = number
    }
    if (contractor && parseInt(contractor) !== 0) {
      headers.contractor = contractor
    }
    if(locations && locations.length > 0) {
      headers.locations = JSON.stringify(locations)
    }

    try {
      const response = await api.request(
        {
          url: '/SSingleOrders/CustomRecordsList',
          method: 'GET',
          headers,
          params: {
            page: page,
            perPage: 10
          }
        }
      );
      let data = response.data.result
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.error);
    }
  }
);

export const getDoneOrInvoicedProjects = createAsyncThunk(
  'projects/doneOrInvoiced/recordsList',
  async ({number, contractor, statuses, page = 1}, thunkAPI) => {
    const headers = {}

    headers.statuses = JSON.stringify([DONE_STATUS, INVOICED_STATUS])

    headers['X-TOKEN'] = localStorage.getItem('token')
    if (number) {
      headers.number = number
    }
    if (contractor && parseInt(contractor) !== 0) {
      headers.contractor = contractor
    }

    try {
      const response = await api.request(
        {
          url: '/SSingleOrders/CustomRecordsList',
          method: 'GET',
          headers,
          params: {
            page: page,
            perPage: 10
          }
        }
      );
      let data = response.data.result
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.error);
    }
  }
);

export const getStartedProjects = createAsyncThunk(
  'projects/started/recordsList',
  async ({number, contractor, statuses, page = 1}, thunkAPI) => {
    const headers = {}

    headers.statuses = JSON.stringify([STARTED_STATUS])

    headers['X-TOKEN'] = localStorage.getItem('token')
    if (number) {
      headers.number = number
    }
    if (contractor && parseInt(contractor) !== 0) {
      headers.contractor = contractor
    }

    try {
      const response = await api.request(
        {
          url: '/SSingleOrders/CustomRecordsList',
          method: 'GET',
          headers,
          params: {
            page: page,
            perPage: 10
          }
        }
      );
      let data = response.data.result
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.error);
    }
  }
);

export const getProject = createAsyncThunk(
  'projects/singleRecord',
  async (projectId, thunkAPI) => {
    try {
      const response = await api.request(
        {
          url: `/SSingleOrders/CustomRecord/${projectId}`,
          method: 'GET',
          headers: {
            'X-TOKEN': localStorage.getItem('token')
          }
        }
      );
      let data = response.data.result || []
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.error);
    }
  }
);

export const getTasks = createAsyncThunk(
  'projects/tasks',
  async ({projectId, page = 1, status}, thunkAPI) => {

    const headers = {}

    headers['X-TOKEN'] = localStorage.getItem('token')

    if (status) {
      headers.status = status
    }

    try {
      const response = await api.request(
        {
          url: `SSingleOrders/AssemblyTasksList/${projectId}`,
          method: 'GET',
          headers,
          params: {
            page: page,
            perPage: 10
          }
        }
      );
      let data = response.data.result || []
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.error);
    }
  }
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    filteredByLocations: {
      recordsList: [],
      page: 1,
      lastPage: 1
    },
    started: {
      recordsList: [],
      page: 1,
      lastPage: 1
    },
    doneOrInvoiced: {
      recordsList: [],
      page: 1,
      lastPage: 1
    },
    contractors: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    setStartedPage: (state, action) => {
      state.started.page = action.payload
    },
    setDoneOrInvoicedPage: (state, action) => {
      state.doneOrInvoiced.page = action.payload
    }
  },
  extraReducers: {
    [getProjectsFilteredByLocations.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.filteredByLocations.recordsList = payload.orders || []
      state.filteredByLocations.lastPage = payload.lastPage
      if (payload.contractors) {
        state.contractors = Object.keys(payload.contractors).map(key => {
          return {
            id: key,
            name: payload.contractors[key]
          }
        })
      }
      return state;
    },
    [getProjectsFilteredByLocations.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getProjectsFilteredByLocations.pending]: (state) => {
      state.isFetching = true;
    },
    [getStartedProjects.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.started.recordsList = payload.orders || []
      state.started.lastPage = payload.lastPage
      if (payload.contractors) {
        state.contractors = Object.keys(payload.contractors).map(key => {
          return {
            id: key,
            name: payload.contractors[key]
          }
        })
      }
      return state;
    },
    [getStartedProjects.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getStartedProjects.pending]: (state) => {
      state.isFetching = true;
    },
    [getDoneOrInvoicedProjects.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.doneOrInvoiced.recordsList = payload.orders || []
      state.doneOrInvoiced.lastPage = payload.lastPage
      if (payload.contractors) {
        state.contractors = Object.keys(payload.contractors).map(key => {
          return {
            id: key,
            name: payload.contractors[key]
          }
        })
      }
      return state;
    },
    [getDoneOrInvoicedProjects.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getDoneOrInvoicedProjects.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { setStartedPage, setDoneOrInvoicedPage } = projectsSlice.actions;

export const projectsSelector = (state) => state.projects;
