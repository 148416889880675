import React from 'react'
import {Box, Button, Divider, Grid, LinearProgress} from "@material-ui/core";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {useHistory} from "react-router-dom";
import {DONE_STATUS, INVOICED_STATUS} from "../../features/Projects/ProjectsSlice";

const ProjectTile = ({project}) => {

  const {isPm} = useSelector(userSelector)
  const history = useHistory()

  return (
    <Box>
      <Divider />
      <Grid container>
        <Grid item xs={isPm ? 7 : 8} lg={isPm ? 9 : 10}>
          <Box pr={2}>
            <h3>{project.number}</h3>
            <p>
              {`Termin umowy: ${project.contractTerm || 'brak'} || ${project.description}`}
            </p>
          </Box>
        </Grid>
        {isPm && <Grid item xs={2}>
          <Box pr={2} height='100%' display='flex' alignItems='center'>
            <div style={{height: '40px', width: '100%', position: 'relative'}}>
              <p
                style={{margin: 0, position: 'absolute', color: '#fff', fontWeight: 900, zIndex: 1, height: '40px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              >
                {project.ready}/{project.total} {project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}%
              </p>
              <LinearProgress style={{height: '40px', width: '100%', borderRadius: '8px'}} variant='determinate' value={project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}/>
            </div>
          </Box>
        </Grid>}
        <Grid item xs={3} lg={isPm ? 1 : 2}>
          <Box height='100%' display='flex' alignItems='center'>
            <Button
              fullWidth
              color={project.status === INVOICED_STATUS || project.status === DONE_STATUS ? 'default' : 'primary'}
              variant='contained'
              onClick={() => isPm ? history.push(`/tasks/${project.id}`) : history.push(`/project/${project.id}`)}
            >
              {isPm ? 'Zadania' : 'Szczegóły'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProjectTile
