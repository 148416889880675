import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getDoneOrInvoicedProjects, getStartedProjects, setStartedPage, setDoneOrInvoicedPage, projectsSelector} from "./ProjectsSlice";
import {Box, Divider, Grid, IconButton, Button, Select, Fade} from '@material-ui/core'
import ProjectTile from "../../common/Components/ProjectTile";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {ArrowForward, Search} from "@material-ui/icons";
import {userSelector} from "../User/UserSlice";
import Pagination from '@material-ui/lab/Pagination';
import {useHistory} from "react-router-dom";

const Projects = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {started, doneOrInvoiced, contractors, isFetching} = useSelector(projectsSelector)
  const {isPm, fetchedUser} = useSelector(userSelector)

  const [projectSearchValue, setProjectSearchValue] = useState('')
  const [contractorSearchValue, setContractorSearchValue] = useState(null)

  useEffect(() => {
    dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page }))
  }, [started.page, history.location])

  useEffect(() => {
    dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
  }, [doneOrInvoiced.page, history.location])

  return (
    <Fade in={fetchedUser}>
      <Box textAlign='left' p={2}>
        <Box mb={5}>
          <Grid container alignItems='center'>
            {isPm && <Grid item xs={6} lg={6}>
              <Box mr={4} display='flex' alignItems='center'>
                <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                  Wyszukaj projekt po kontrahencie:
                </h5>
                <Select
                  value={contractorSearchValue}
                  onChange={e => {
                    setContractorSearchValue(e.target.value)
                  }}
                  native
                  style={{height: '40px', width: '100%'}}
                  variant='outlined'
                >
                  <option value={0}>
                    Wszyscy
                  </option>
                  {
                    contractors.map(contractor => {
                      return (
                        <option value={parseInt(contractor.id)}>
                          {contractor.name}
                        </option>
                      )
                    })
                  }
                </Select>
                <IconButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page }))
                    dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                  }}
                  style={{marginLeft: '10px'}}
                >
                  <ArrowForward/>
                </IconButton>
              </Box>
            </Grid>}
            <Grid item xs={6} lg={6}>
              <Box mr={4} display='flex' alignItems='center'>
                <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                  Wyszukaj projekt po numerze:
                </h5>
                <OutlinedInput
                  style={{height: '40px', width: '100%'}}
                  type='text'
                  value={projectSearchValue}
                  onChange={(e) => setProjectSearchValue(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      dispatch(getStartedProjects({ number: e.target.value, contractor: contractorSearchValue, page: started.page }))
                      dispatch(getDoneOrInvoicedProjects({ number: e.target.value, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                    }
                  }}
                />
                <IconButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page }))
                    dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                  }}
                  style={{marginLeft: '10px'}}
                >
                  <Search />
                </IconButton>
              </Box>
            </Grid>
            {!isPm && <Grid item xs={6} lg={6}>
              <Box display='flex' alignItems='center'>
                <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                  lub sprawdź
                </h5>
                <Button color='primary' variant='contained' onClick={() => history.push('/locations')}>
                  historia prac na lokalizacji
                </Button>
              </Box>
            </Grid>}
          </Grid>
          <Divider />
        </Box>
        <Box>
          <h2>
            Projekty uruchomione:
          </h2>
          {
            started.recordsList.length === 0
              ? <p style={{margin: '20px 0'}}>Brak wyników</p>
              : started.recordsList.map(
              item => {
                return <ProjectTile key={item.id} project={item} />
              }
              )
          }
          <Divider />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Pagination shape='rounded' page={started.page} onChange={(event, page) => dispatch(setStartedPage(page))} count={started.lastPage} />
          </Box>
        </Box>
        <Box mt={10}>
          <h2>
            Projekty zrealizowane i zafakturowane:
          </h2>
          {
            doneOrInvoiced.recordsList.length === 0
              ? <p style={{margin: '20px 0'}}>Brak wyników</p>
              : doneOrInvoiced.recordsList.map(
              item => {
                return <ProjectTile key={item.id} project={item} />
              }
              )
          }
          <Divider />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Pagination shape='rounded' page={doneOrInvoiced.page} onChange={(event, page) => dispatch(setDoneOrInvoicedPage(page))} count={doneOrInvoiced.lastPage} />
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

export default Projects
