import {Box, Button} from "@material-ui/core";
import CRMexpert from '../../assets/images/CRMexpert.png'
import {ExitToApp} from "@material-ui/icons";
import React, { Fragment } from "react";
import {useHistory} from "react-router-dom";
import {userSelector} from "../../features/User/UserSlice";
import {useSelector} from "react-redux";


const Header = () => {
  const history = useHistory();

  const { name } = useSelector(userSelector)


  const onLogOut = () => {
    localStorage.removeItem('token');

    history.push('/login');
  };

  return (
    <Fragment>
      <Box style={{backgroundColor: '#000'}} p={[1, 2, 3]} display='flex' justifyContent='space-between' alignItems='center'>
        <Box maxHeight={['20px', '25px', '50px']} onClick={() => window.location.reload()}>
          <img src={CRMexpert} alt='logo' style={{maxHeight: 'inherit', cursor: 'pointer'}} />
        </Box>
        <Box display='flex'>
          <p style={{marginRight: '20px', fontSize: '12px', color: "white"}}>Zalogowano jako: <b>{name}</b></p>
          <Button
            style={{margin: 0}}
            onClick={onLogOut}
            startIcon={<ExitToApp/>}
          >
            Wyloguj
          </Button>
        </Box>
      </Box>
      <div style={{width: '100%', height: '1px', boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.25)'}} />
    </Fragment>
  )
}

export default Header
