import {useHistory, useParams} from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField
} from "@material-ui/core";
import {ArrowBack, Check, Close, InsertDriveFile, LocationOn, NoteAdd, PictureAsPdf} from "@material-ui/icons";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {api} from "../../api";
import IconButton from "@material-ui/core/IconButton";
import {toast} from "react-toastify";


const TaskDetails = ({tasks, projectId, doneTasksFetched, toDoTasksFetched}) => {

  const {taskId} = useParams()
  const history = useHistory()
  const { isPm } = useSelector(userSelector)

  const [ taskStatus, setTaskStatus ] = useState(false)
  const [ taskHasChanged, setTaskHasChanged ] = useState(false)

  const [ imagePreview, setImagePreview ] = useState(false)
  const [ showImagePreview, setShowImagePreview ] = useState(false)
  const [ preAssemblyDocs, setPreAssemblyDocs ] = useState([])
  const [ postAssemblyDocs, setPostAssemblyDocs ] = useState([])
  const [ documentsLoaded, setDocumentsLoaded ] = useState(true)

  const [fileToDelete, setFileToDelete] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)

  const [taskNote, setTaskNote] = useState('')
  const task = tasks.find(task => task.id == taskId)

  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  const updateTask = () => {
    return api.put(
      `/AssemblyTasks/Record/${taskId}`,
      {
        'task_status': taskStatus ? 'Zrealizowane' : 'Do realizacji',
        'technician_notes': taskNote
      },
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    ).then(() => toast.success('Zadanie zostało zaktualizowane'))
  }

  const handleCapture = ({ target }, action, prevFiles, docType) => {
    const newFiles = [...target.files]
    const transformed = newFiles.map(file => ({
      data: file,
      fileName: file.name,
      type: file.type,
      title: file.name
    }))

    Promise.all(newFiles.map(file => {
      let formData = new FormData();

      formData.append("filename", file)
      formData.append("projektdokument", projectId)
      formData.append("sourceRecord", taskId)
      formData.append("notes_title", file.name)
      formData.append("typdokumentu", docType)
      formData.append('filelocationtype', 'I')
      formData.append('sourceModule', 'AssemblyTasks')
      formData.append('relationOperation', true)
      formData.append('relationId', 651)

      return api.post("/Documents/Record", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'multipart/form-data',
          'X-TOKEN': localStorage.getItem('token')
        },
      }).then(res => ({
        data: file,
        fileName: file.name,
        type: file.type,
        title: file.name,
        id: res?.data?.result?.id
      }))
    })).then((newFiles) => {
      action([...prevFiles, ...newFiles])
    })

    target.value = null
  }

  const deleteFile = (id) => {
    return api.request({
      url: `/Documents/Record/${id}`,
      method: 'DELETE',
      headers: {
        'X-TOKEN': localStorage.getItem('token')
      }
    })
  }

  useEffect(() => {
    if (task?.documents.length > 0) {
      setDocumentsLoaded(false)
      const preAssembly = task.documents.filter(doc => doc.type === 'Dokumentacja przedmontażowa')
      const postAssembly = task.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')

      Promise.all(preAssembly.map(preAssemblyDocument => {
        return getFile(preAssemblyDocument).then(res => ({...preAssemblyDocument, url: res?.data?.result.content, type: res?.data?.result.type}))
      })).then(res => {
        setPreAssemblyDocs(res)
      })

      Promise.all(postAssembly.map(preAssemblyDocument => {
        return getFile(preAssemblyDocument).then(res => ({...preAssemblyDocument, url: res?.data?.result.content, type: res?.data?.result.type}))
      })).then(res => {
        setPostAssemblyDocs(res)
        setDocumentsLoaded(true)
      })
    }
  }, [task])

  useEffect(() => {
    if (task?.status) {
      setTaskStatus(task.status === 'Zrealizowane')
    }

    if (task?.note) {
      setTaskNote(task.note)
    }
  },[task])

  if (!doneTasksFetched || !toDoTasksFetched) {
    return null
  }

  return (
    <Box textAlign='left' p={2}>
      <Box mb={2}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => history.push(`/tasks/${projectId}`)}
          color='secondary'
        >
          Wróc do listy zadań
        </Button>
      </Box>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontrahent:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{task.contractor || '-'}</p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Zlecenie:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.orderName || '-'}
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin realizacji:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.implementationDate || '-'}
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontakt:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {
                    task.contact ? <span>
                        {task.contact.name},&nbsp;
                        <a href={`mailto: ${task.contact.email}`}>{task.contact.email}</a>&nbsp;
                        <a href={`tel: ${task.contact.phone}`}>{task.contact.phone}</a>
                      </span>
                      : '-'
                  }
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Opis:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.description || '-'}
                </p>
              </Grid>

            </Grid>
          </Grid>


          <Grid item xs={12} lg={6}>
            <Grid container>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Numer projektu:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.projectNumber || '-'}
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Dostawca:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.supplier || '-'}
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Lokalizacja:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px', position: 'relative'}}>
                  {
                    task.location
                      ? <a href={`https://www.google.com/maps/place/${task.location.street ? task.location.street : ''}+${task.location.street && task.location.buildingNumber ? task.location.buildingNumber : ''},+${task.location.city ? task.location.city : ''}`} target='_blank'>
                        {task.location.city} {task.location.street ? `${task.location.street} ${task.location.buildingNumber ? task.location.buildingNumber : ''}` : ''}
                      </a>
                      : '-'
                  }
                </p>
              </Grid>

              <Grid item xs={3} xl={2} alignItems='center'>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}><b>Zrealizowane:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '4px', marginBottom: '8px'}}>
                  <Checkbox
                    style={{padding: 0}}
                    color='primary'
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    checked={taskStatus}
                    onChange={(ev) => {
                      setTaskStatus(ev.target.checked)
                      setTaskHasChanged(true)
                    }}
                  />
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Notatka technika:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    multiline
                    rows={2}
                    rowsMax={10}
                    value={taskNote}
                    onChange={(ev) => {
                      setTaskNote(ev.target.value)
                      setTaskHasChanged(true)
                    }}
                  />
                </p>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='flex-end' mt={1}>
              <Button
                color='primary'
                variant='contained'
                onClick={updateTask}
              >
                Zaktualizuj zadanie
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid item xs={12}>
        <Box mt={2} mb={5}>
          <h3>Dokumentacja przedmontażowa:</h3>
          {!documentsLoaded
            ? <CircularProgress />
            : <Grid container alignItems='center'>
                {preAssemblyDocs.map(doc => {
                  if (doc.type === 'image/png' || doc.type === 'image/jpg' || doc.type === 'image/jpeg') {
                    return (
                      <Grid item xs={2}>
                        <Box mr={2}>
                          <img
                            onClick={() => {
                              setShowImagePreview(true)
                              setImagePreview(`${doc.url}`)
                            }}
                            style={{maxWidth: '100%', borderRadius: '8px', cursor: 'pointer'}}
                            alt={doc.title}
                            src={`${doc.url ? doc.url : URL.createObjectURL(doc.data)}`}
                          />
                          <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all'}}>
                            {doc.fileName}
                          </p>
                          <Box display='flex' justifyContent='center'>
                            <Button
                              onClick={() => {
                                setFileToDelete(doc)
                                setConfirmationDialog(true)
                              }}
                            >
                              usuń
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  } else if (doc.type === 'application/pdf') {
                    return (
                      <Grid item xs={2}>
                        <Box mr={2}>
                          <Box display='flex' justifyContent='center'>
                            <IconButton color='primary' onClick={() => {
                              var a = document.createElement("a"); //Create <a>
                              a.href = doc.url; //Image Base64 Goes here
                              a.download = doc.fileName; //File name Here
                              a.click(); //Downloaded file
                            }}>
                              <PictureAsPdf style={{fontSize: 80}} />
                            </IconButton>
                          </Box>
                          <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all'}}>
                            {doc.fileName}
                          </p>
                        </Box>
                        <Box display='flex' justifyContent='center'>
                          <Button
                            onClick={() => {
                              setFileToDelete(doc)
                              setConfirmationDialog(true)
                            }}
                          >
                            usuń
                          </Button>
                        </Box>
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid item xs={2}>
                        <Box mr={2}>
                          <Box display='flex' justifyContent='center'>
                            <IconButton color='primary' onClick={() => {
                              var a = document.createElement("a"); //Create <a>
                              a.href = doc.url; //Image Base64 Goes here
                              a.download = doc.fileName; //File name Here
                              a.click(); //Downloaded file
                            }}>
                              <InsertDriveFile style={{fontSize: 80}} />
                            </IconButton>
                          </Box>
                          <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all'}}>
                            {doc.fileName}
                          </p>
                        </Box>
                        <Box display='flex' justifyContent='center'>
                          <Button
                            onClick={() => {
                              setFileToDelete(doc)
                              setConfirmationDialog(true)
                            }}
                          >
                            usuń
                          </Button>
                        </Box>
                      </Grid>
                    )
                  }
                })}
              <Grid item xs={2}>
                <Box mb={2}>
                  <Box display='flex' justifyContent='center'>
                    <IconButton color='primary' component='label'>
                      <input
                        type='file'
                        accept='image/jpg, image/png, image/jpeg, application/pdf, application/docx, application/xlsx'
                        hidden
                        multiple
                        onChange={ev => handleCapture(ev, setPreAssemblyDocs, preAssemblyDocs, 'Dokumentacja przedmontażowa')}
                      />
                      <NoteAdd style={{fontSize: 100}} />
                    </IconButton>
                  </Box>
                </Box>
                <Box display='flex' justifyContent='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    component='label'
                  >
                    <input
                      type='file'
                      accept='image/jpg, image/png, image/jpeg, application/pdf, application/docx, application/xlsx'
                      hidden
                      multiple
                      onChange={ev => handleCapture(ev, setPreAssemblyDocs, preAssemblyDocs, 'Dokumentacja przedmontażowa')}
                    />
                    Dodaj plik
                  </Button>
                </Box>
              </Grid>
            </Grid>}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} mb={5}>
          <h3>Dokumentacja pomontażowa:</h3>
          {!documentsLoaded
            ? <CircularProgress />
            : <Grid container alignItems='center'>
              {postAssemblyDocs.map(doc => {
                if (doc.type === 'image/png' || doc.type === 'image/jpg' || doc.type === 'image/jpeg') {
                  return (
                    <Grid item xs={2}>
                      <Box mr={2}>
                        <img
                          onClick={() => {
                            setShowImagePreview(true)
                            setImagePreview(`${doc.url}`)
                          }}
                          style={{maxWidth: '100%', borderRadius: '8px', cursor: 'pointer'}}
                          alt={doc.title}
                          src={`${doc.url ? doc.url : URL.createObjectURL(doc.data)}`}
                        />
                        <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all'}}>
                          {doc.fileName}
                        </p>
                      </Box>
                      <Box display='flex' justifyContent='center'>
                        <Button
                          onClick={() => {
                            setFileToDelete(doc)
                            setConfirmationDialog(true)
                          }}
                        >
                          usuń
                        </Button>
                      </Box>
                    </Grid>
                  )
                } else if (doc.type === 'application/pdf') {
                  return (
                    <Grid item xs={2}>
                      <Box mr={2}>
                        <Box display='flex' justifyContent='center'>
                          <IconButton color='primary' onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = doc.url; //Image Base64 Goes here
                            a.download = doc.fileName; //File name Here
                            a.click(); //Downloaded file
                          }}>
                            <PictureAsPdf style={{fontSize: 80}} />
                          </IconButton>
                        </Box>
                        <p
                          onClick={() => {
                            var a = document.createElement("a");
                            a.href = doc.url;
                            a.download = doc.fileName;
                            a.click();
                          }}
                          style={{textAlign: 'center', cursor: 'pointer', marginTop: '5px', wordBreak: 'break-all'}}>
                          {doc.fileName}
                        </p>
                      </Box>
                      <Box display='flex' justifyContent='center'>
                        <Button
                          onClick={() => {
                            setFileToDelete(doc)
                            setConfirmationDialog(true)
                          }}
                        >
                          usuń
                        </Button>
                      </Box>
                    </Grid>
                  )
                } else {
                  return (
                    <Grid item xs={2}>
                      <Box mr={2}>
                        <Box display='flex' justifyContent='center'>
                          <IconButton color='primary' onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = doc.url; //Image Base64 Goes here
                            a.download = doc.fileName; //File name Here
                            a.click(); //Downloaded file
                          }}>
                            <InsertDriveFile style={{fontSize: 80}} />
                          </IconButton>
                        </Box>
                        <p
                          onClick={() => {
                            var a = document.createElement("a");
                            a.href = doc.url;
                            a.download = doc.fileName;
                            a.click();
                          }}
                          style={{textAlign: 'center', cursor: 'pointer', marginTop: '5px', wordBreak: 'break-all'}}>
                          {doc.fileName}
                        </p>
                      </Box>
                      <Box display='flex' justifyContent='center'>
                        <Button
                          onClick={() => {
                            setFileToDelete(doc)
                            setConfirmationDialog(true)
                          }}
                        >
                          usuń
                        </Button>
                      </Box>
                    </Grid>
                  )
                }
              })}
              <Grid item xs={2}>
                <Box mb={2}>
                  <Box display='flex' justifyContent='center'>
                    <IconButton color='primary' component='label'>
                      <input
                        type='file'
                        accept='image/jpg, image/png, image/jpeg, application/pdf, application/docx, application/xlsx'
                        hidden
                        multiple
                        onChange={ev => handleCapture(ev, setPostAssemblyDocs, postAssemblyDocs, 'Dokumentacja pomontażowa')}
                      />
                      <NoteAdd style={{fontSize: 100}} />
                    </IconButton>
                  </Box>
                </Box>
                <Box display='flex' justifyContent='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    component='label'
                  >
                    <input
                      type='file'
                      accept='image/jpg, image/png, image/jpeg, application/pdf, application/docx, application/xlsx'
                      hidden
                      multiple
                      onChange={ev => handleCapture(ev, setPostAssemblyDocs, postAssemblyDocs, 'Dokumentacja pomontażowa')}
                    />
                    Dodaj plik
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
        </Box>
      </Grid>
      <Dialog maxWidth='lg' open={showImagePreview} onClose={() => {
        setShowImagePreview(false)
      }}>
        <Box display='flex' justifyContent='flex-end'>
          <IconButton size='large' onClick={() => {
            setShowImagePreview(false)
          }}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          {imagePreview && <img style={{height: 'auto', width: '100%'}} alt='preview' src={imagePreview}/>}
        </DialogContent>
      </Dialog>

      <Dialog maxWidth='lg' open={confirmationDialog} onClose={() => setConfirmationDialog(false)}>
        <DialogContent>
          Czy na pewno chcesz usunąć dokument <b>{fileToDelete?.fileName}</b>?
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => {
            setConfirmationDialog(false)
          }} color="primary" startIcon={<Close />}>
            Nie
          </Button>
          <Button variant='contained' onClick={() => {
            setConfirmationDialog(false)
            deleteFile(fileToDelete?.id).then(() => {
              const filteredPre = preAssemblyDocs.filter(doc => doc.id !== fileToDelete?.id)
              const filteredPost = postAssemblyDocs.filter(doc => doc.id !== fileToDelete?.id)
              setPreAssemblyDocs(filteredPre)
              setPostAssemblyDocs(filteredPost)
              toast.warning('Dokument został usunięty')
            })
          }} color="primary" autoFocus startIcon={<Check />}>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TaskDetails
