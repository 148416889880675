import axios from 'axios'

// VPM

const api = axios.create({
  // baseURL: 'https://stage.vpm.crmexpert.co/webservice',
  baseURL: 'https://demo.crmexpert.co/webservice',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Basic ${process.env.REACT_APP_X_API_TOKEN}`,
    'X-API-KEY': process.env.REACT_APP_X_API_KEY,
  }
})

api.interceptors.response.use(
  res => res,
  error => {

    const token = localStorage.getItem('token')
    if (error.response.status === 401 && token) {
      localStorage.removeItem('token')
      window.location = '/login'
    }

    return Promise.reject(error)
  }
)

export { api }
